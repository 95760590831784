.chat-section-alignment {
  .chat-grid {
    display: grid;
    grid-template-columns: 350px minmax(0, 1fr) 350px;
    gap: 0 20px;

    height: calc(100vh - 214px);
    .char-grid-items {
      border: 1px solid #e6e6e6;
    }
    .left-side-message-end {
      display: flex;
      justify-content: flex-end;
    }
    .chat-box-height-main {
      overflow: auto;
      overflow-x: hidden;
      height: calc(100vh - 409px);
      margin: 30px;
      margin-right: 0px;
      padding-right: 30px;
      .left-side-message-start,
      .left-side-message-end {
        .left-side-message {
          p {
            padding: 5px 10px;
            background-color: #0a80ff;
            font-size: 16px;
            color: #fff;
            border-radius: 4px;
            width: fit-content;
          }
        }
      }
    }

    .input-grid {
      display: grid;
      grid-template-columns: minmax(0, 1fr) 140px;
      gap: 0 20px;
      justify-content: space-between;
      .input-grid-items {
        button {
          background-color: #0a80ff;
          padding: 0 10px;
          height: 50px;
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          text-align: center;
          width: 100%;
          border: 1px solid transparent;
        }
        input {
          background-color: #fff;
          height: 50px;
          border: 1px solid #e6e6e6;
          padding: 0 20px;
          font-size: 16px;
          font-weight: 400;
          width: 100%;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .char-grid-items {
      border-right: 1px solid #e6e6e6;
      word-break: break-all;
      .option-alignment {
        display: flex;
        align-items: center;
        svg {
          width: 16px;
        }
        .option-design:last-child {
          margin-right: 0px;
        }
        .option-design {
          cursor: pointer;
          border: 1px solid #e6e6e6;
          width: 46px;
          height: 36px;
          border-radius: 4px;
          display: flex;
          margin-right: 10px;
          align-items: center;
          justify-content: center;
        }
      }
      .profile-typing-grid {
        display: grid;
        grid-template-columns: minmax(0, 1fr) 170px;
        justify-content: space-between;
        gap: 0 20px;
        padding: 20px;
        background-color: #fff;
        .profile-typing-grid-items {
          .user-grid {
            display: grid;
            grid-template-columns: 36px minmax(0, 1fr);
            gap: 0 15px;
            .user-grid-items {
              h5 {
                font-size: 16px;
                color: #323232;
                font-weight: 500;
                margin: 0;
              }
              i {
                color: #0abb87;
                font-weight: 400;
              }
              img {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                object-fit: cover;
              }
            }
          }
        }
      }
      .full-information {
        padding: 20px;
        p {
          font-size: 14px;
          line-height: 20px;
          color: #323232;
        }
      }
      .profile-information {
        p {
          font-size: 16px;
          color: #323232;
          font-weight: 600;
          margin: 0 0 5px 0;
          text-align: center;
        }
        span {
          display: block;
          font-size: 12px;
          text-align: center;
        }
      }
      .main-profile-image-alignment {
        padding: 30px 0 10px 0;
        display: flex;
        justify-content: center;
        img {
          width: 97px;
          height: 97px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .profile-close-alignment {
        display: flex;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid #e6e6e6;
        justify-content: space-between;
        p {
          font-size: 20px;
          line-height: 30px;
          color: #323232;
          margin: 0;
        }
      }
      .chat-box-messgae-align {
        width: 100%;
      }
      .chat-profile-height {
        height: calc(100vh - 308px);
        overflow: scroll;
        overflow-x: hidden;

        .profile-grid {
          display: grid;
          grid-template-columns: 36px minmax(0, 1fr) 80px;
          gap: 0 10px;
          align-items: center;
          padding: 15px;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            background-color: #fff;
          }
          .profile-grid-items {
            small {
              color: #0a80ff;
              font-size: 11px;
              text-align: right;
            }
            .time-right-side {
              display: flex;
              justify-content: flex-end;
              margin-top: 5px;
            }
            .counter-alignment {
              display: flex;
              justify-content: flex-end;
              .counter-design {
                width: 23px;
                height: 23px;
                background-color: #0a80ff;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 13px;
              }
            }
            p {
              font-size: 16px;
              color: #0a80ff;
              margin: 0;
            }
            span {
              font-size: 14px;
              color: #969696;
            }
            img {
              width: 36px;
              height: 36px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
        }
      }
      .input-search-relative {
        padding: 20px;
        input {
          width: 100%;
          height: 50px;
          padding: 0 20px;
          font-size: 16px;
          border: 1px solid #e6e6e6;
          border-radius: 6px;
          outline: none;
          &:focus {
            outline: none;
          }
          ::placeholder {
            font-size: 16px;
            color: #323232;
          }
        }
      }
    }
  }
}
