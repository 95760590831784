.lodar-position {
  display: flex;
  justify-content: center;
}

.Nodata {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 390px 0;
  align-items: center;
}

.back-bridge {
  transition: all 0.4s ease-in-out;
  color: #76838f;
  text-decoration: none;
  background-color: transparent;
  box-sizing: inherit;
  font-size: 16px;
  padding-top: 10px;
  padding-left: 7px;
}

// chat code start

.card {
  background: #fff;
  transition: 0.5s;
  border: 0;

  margin-bottom: 30px;
  border-radius: 0.55rem;
  position: relative;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
}

.chat-app .people-list {
  width: 320px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px;
  z-index: 7;
}

.chat-app .chat {
  height: 90vh;
  margin-left: 341px;
  border-left: 1px solid #eaeaea;
}

.people-list {
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.people-list .chat-list li {
  padding: 10px 15px;
  list-style: none;
  border-radius: 3px;
  display: flex;
}

.people-list .chat-list li:hover {
  background: #efefef;
  cursor: pointer;
}

.people-list .chat-list li.active {
  background: #efefef;
}

.people-list .chat-list li .name {
  font-size: 15px;
}

.people-list .chat-list img {
  width: 45px;
  border-radius: 50%;
}

.people-list img {
  float: left;
  border-radius: 50%;
}

.people-list .about {
  float: left;
  padding-left: 8px;
}

.people-list .status {
  color: #999;
  font-size: 13px;
}

.chat .chat-header {
  padding: 15px 20px;
  border-bottom: 2px solid #f4f7f6;
}

.chat .chat-header img {
  float: left;
  border-radius: 40px;
  width: 40px;
}

.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px;
}

.chat .chat-history {
  padding: 20px;
  border-bottom: 2px solid #fff;
  height: calc(100vh - 250px);
  overflow: auto;
  overflow-x: hidden;
}

.people-list {
  height: calc(100vh - 250px);
  overflow: auto;
  overflow-x: hidden;
}

.full-sw {
  width: 100%;
  padding-left: 280px;
  padding-right: 20px;
}

.chat .chat-history ul {
  padding: 0;
}

.chat .chat-history ul li {
  list-style: none;
  margin-bottom: 30px;
}

.chat .chat-history ul li:last-child {
  margin-bottom: 0px;
}

.chat .chat-history .message-data {
  margin-bottom: 15px;
}

.chat .chat-history .message-data img {
  border-radius: 40px;
  width: 40px;
}

.chat .chat-history .message-data-time {
  color: #434651;
  padding-left: 6px;
}

.chat .chat-history .message {
  color: #444;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  display: inline-block;
  position: relative;
}

.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.chat .chat-history .my-message {
  background: #efefef;
}

.chat .chat-history .my-message:after {
  bottom: 100%;
  left: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #efefef;
  border-width: 10px;
  margin-left: -10px;
}

.chat .chat-history .other-message {
  background: #e8f1f3;
  text-align: right;
}

.chat .chat-history .other-message:after {
  border-bottom-color: #e8f1f3;
  left: 93%;
}

.chat .chat-message {
  padding: 20px;
}

.online,
.offline,
.me {
  margin-right: 2px;
  font-size: 8px;
  vertical-align: middle;
}

.online {
  color: #86c541;
}

.offline {
  color: #e47297;
}

.me {
  color: #1d8ecd;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

@media only screen and (max-width: 767px) {
  .chat-app .people-list {
    height: 465px;
    width: 100%;
    overflow-x: auto;
    background: #fff;
    left: -400px;
    display: none;
  }

  .chat-app .people-list.open {
    left: 0;
  }

  .chat-app .chat {
    margin: 0;
  }

  .chat-app .chat .chat-header {
    border-radius: 0.55rem 0.55rem 0 0;
  }

  .chat-app .chat-history {
    height: 300px;
    overflow-x: auto;
  }
}

.no-answer-alignment {
  p {
    font-size: 15px;
    color: #222;
    margin: 0;
    text-align: center;
  }
}

.no-answer-image-center {
  display: flex;
  justify-content: center;

  img {
    width: 300px;
    height: 310px;
  }
}

.chat-list-answer-style {
  height: calc(100vh - 302px);
  padding: 25px;
  overflow-x: hidden;
  overflow: auto;
  position: relative;

  .chat-message {
    display: flex;
    align-items: start;

    .message-block {
      .message-text button::before {
        position: absolute;
        display: block;
        content: "";
        top: 0;
        width: 0;
        left: -10px;
        border-top: 10px solid #fce7ec;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      }

      .message-text {
        button {
          position: relative;
          color: #222;
          position: relative;
          background-color: #fce7ec;
          font-weight: 400;
          word-break: break-word;
          width: auto;
          max-width: 420px;
          padding: 10px;
          border: none;
        }
      }

      .top-message-part {
        text-align: right;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: flex-end;

        svg {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }

        span {
          font-size: 0.7rem;
          color: #999;
          display: inline-block;
          padding-right: 4px;
        }
      }
    }
  }
}

.center-align-image-no-data {
  display: flex;
  justify-content: center;
  padding: 20%;
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .chat-app .chat-list {
    height: 650px;
    overflow-x: auto;
  }

  .chat-app .chat-history {
    height: 600px;
    overflow-x: auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .chat-app .chat-list {
    height: 480px;
    overflow-x: auto;
  }

  .chat-app .chat-history {
    height: calc(100vh - 350px);
    overflow-x: auto;
  }
}

.tester-no {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.tester {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // &:nth-child(odd) {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-start;
  // }
  // &:nth-child(even) {
  //   display: flex;
  //   align-items: flex-end;
  //   flex-direction: column;
  // }
}

.dynamic-width-table {
  table-layout: auto;
}


.react-data-table-component .rdt_TableCell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}