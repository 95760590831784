//
// Base
//

// Body
body {
  background: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {

  // Wrapper
  .wrapper {

    // Fixed Header
    .header-fixed & {
      padding-top: get($header-config, desktop, fixed, height);
    }

    // Fixed Content Head
    .header-fixed.subheader-fixed.subheader-enabled & {
      padding-top: get($header-config, desktop, fixed, height) + get($subheader-config, fixed, height);
    }

    // Fixed Aside
    .aside-fixed & {
      padding-left: get($aside-config, base, width);
    }

    // Minimize Aside
    .aside-fixed.aside-minimize:not(.aside-minimize-hover) & {
      padding-left: get($aside-config, base, minimized-width);
    }
  }

  // Container
  .container,
  .container-fluid {
    padding: 0 get($page-padding, desktop);
  }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {

  // Wrapper
  .wrapper {

    // Fixed Header
    .header-mobile-fixed & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height);
    }

    // Fixed Subheader
    .header-mobile-fixed.subheader-mobile-fixed.subheader-enabled & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height) + get($subheader-config, fixed, height-mobile);
    }
  }

  // Container
  .container,
  .container-fluid {
    max-width: none;
    padding: 0 get($page-padding, tablet-and-mobile);
  }
}

.uqs-title-style {
  padding: 20px 20px 0 20px;

}

.no-data-center-align {
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    color: #333;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }
}

.profile-typing-grid1 {
  align-items: center;
  border-bottom: 1px solid #d0d0d0;

  .profile-typing-grid-items {
    .user-grid {
      align-items: center;
    }
  }
}


.profile-container {
  max-width: 1380px;
  padding: 0 20px;
  margin: 0 auto;

  .profile-grid {
    display: grid;
    grid-template-columns: 350px 1fr 350px;
    justify-content: space-between;
    padding: 2rem 0 0 0;
    gap: 0 20px;

    .profile-grid-items {
      .profile-same-box-design {
        padding: 30px;
        background-color: #fff;
        box-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
        border: 1px solid rgba(0, 0, 0, .125);

        .sub-child-text {
          p {
            font-size: 14px;
            color: #757575;
            margin: 0;
          }
        }

        .top-subjects-title {
          padding-bottom: 1rem;

          p {
            font-size: 18px;
            color: #323232;
            margin: 0;
          }
        }

        .text-grid {
          display: grid;
          grid-template-columns: 150px 1fr;
          gap: 0 20px;
          padding-bottom: 10px;

          .text-grid-items {
            p {
              font-size: 13px;
              color: #757575;
              margin: 0;
            }

            span {
              font-size: 12px;
              color: #323232;
              display: block;
            }
          }
        }

        .profile-details {
          padding: 1rem 0 0 0;

          p {
            font-size: 20px;
            line-height: 30px;
            color: #323232;
            margin: 0;
            text-align: center;
          }

          span {
            font-size: 16px;
            color: #757575;
            text-align: center;
            display: block;
          }
        }

        .profile-image-center-align {
          display: flex;
          justify-content: center;

          .profile-image-show {
            border-radius: 50%;
            border: 1px solid red;
            width: 90px;
            height: 90px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          img {
            width: 90px;
            height: 90px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }
    }
  }
}


// copy
.profile-containerForUser {

  // max-width: 1380px;
  // padding: 0 20px;
  // margin: 0 auto;
  .profile-grid {

    // display: grid;
    // grid-template-columns: 350px 1fr 350px;
    // justify-content: space-between;
    // padding: 2rem 0 0 0;
    // gap: 0 20px;
    .profile-grid-items {
      .profile-same-box-design {

        // padding: 30px;
        // background-color: #fff;
        // box-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
        // border: 1px solid rgba(0,0,0,.125);
        .sub-child-text {
          p {
            font-size: 14px;
            color: #757575;
            margin: 0;
          }
        }

        .top-subjects-title {
          padding-bottom: 1rem;

          p {
            font-size: 18px;
            color: #323232;
            margin: 0;
          }
        }

        .text-grid {
          display: grid;
          grid-template-columns: 150px 1fr;
          gap: 0 20px;
          padding-bottom: 10px;

          .text-grid-items {
            p {
              font-size: 13px;
              color: #757575;
              margin: 0;
            }

            span {
              font-size: 12px;
              color: #323232;
              display: block;
            }
          }
        }

        .profile-details {
          padding: 1rem 0 0 0;

          p {
            font-size: 20px;
            line-height: 30px;
            color: #323232;
            margin: 0;
            text-align: center;
          }

          span {
            font-size: 16px;
            color: #757575;
            text-align: center;
            display: block;
          }
        }

        .profile-image-center-align {
          display: flex;
          justify-content: center;

          .profile-image-show {
            border-radius: 50%;
            border: 1px solid red;
            width: 90px;
            height: 90px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          img {
            width: 90px;
            height: 90px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }
    }
  }
}



.admin-chat-board-design {
  .message-dt.st3 {
    float: left;
  }

  .message-dt {
    /* float: left; */
    width: 60%;
    padding-left: 5px;
  }

  .message-inner-dt {
    float: left;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    border-radius: 15px;
    width: 100%;
  }

  .message-dt.st3 .message-inner-dt>div {
    float: left;
    width: fit-content;
    min-width: 40%;
  }

  .message-inner-dt>div {
    /* float: right; */
    width: 60%;
    /* background-color: #9ab1d717; */
    font-size: 14px;
    line-height: 21px;
    padding: 0px 15px 0px 15px;
    color: #3a3a3a;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    border-radius: 15px;
    font-weight: 400 !important;
  }

  .text-right {
    text-align: right !important;
    margin: 0 0 0px 0 !important;
  }

  .message-dt.st3 .message-inner-dt>div p.message-chat {
    background-color: #fce7ec;
    color: #3a3a3a;
    width: 100%;
    padding: 10px 10px;
    float: left;
    font-weight: 400 !important;
    border-radius: 1px 20px 20px 20px;
    word-break: break-word;
  }

  .message-dt.st3 .message-inner-dt>div p.message-chat:before {
    left: 7px;
    position: absolute;
    display: block;
    content: '';
    top: 22px;
    width: 0;
    height: 0;
    border-top: 28px solid #fce7ec;
    border-left: 14px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 5px solid transparent;
  }

  .message-dt.st3 .message-inner-dt>div p.message-chat * {
    background-color: #fce7ec !important;
  }

  .main-message-box.ta-right {
    float: right;
  }

  .main-message-box {
    float: left;
    position: relative;
    margin-bottom: 5px;
    clear: both;
    width: 100%;
  }

  .main-message-box.ta-right .message-dt {
    padding-left: 0;
    float: right;
    padding-right: 5px;
    width: 60%;
    padding-left: 5px;
    text-align: left;
  }

  .message-dt {
    /* float: left; */
    width: 60%;
    padding-left: 5px;
  }

  .message-inner-dt {
    float: left;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    border-radius: 15px;
    width: 100%;
  }

  .main-message-box.ta-right .message-inner-dt>div {
    float: right;
    width: fit-content;
    min-width: 40%;
  }

  .main-message-box.ta-right .message-inner-dt>div p.message-chat {
    background-color: rgb(2 31 76 / 73%);
    color: #fff;
    width: 100%;
    padding: 10px 10px 10px;
    float: left;
    font-weight: 400 !important;
    border-radius: 20px 1px 20px 20px;
  }

  .main-message-box.ta-right .message-dt .message-inner-dt>div p.message-chat:before {
    right: 10px;
    position: absolute;
    display: block;
    content: '';
    top: 21px;
    width: 0;
    height: 0;
    border-top: 30px solid #465c7c;
    border-left: 20px solid transparent;
    border-right: 12px solid transparent;
  }
}