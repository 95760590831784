
.frequently-asked-questions-position-input {
    text-align: center;
    border: none;
    border-bottom: 1px solid black;
}

.frequently-asked-questions-position-input:focus,
.frequently-asked-questions-position-input:active,
.frequently-asked-questions-position-input:focus-visible { 
    border: none;
    outline: none;
    border-bottom: 1px solid black;
}