.site-setting-card-alignment {
  padding: 25px;
  border: 1px solid #e8e8e8;
  background-color: #fbfbfb;
  margin-bottom: 40px;
  .cus-table-grid {
      display: grid;
      grid-template-columns: repeat(2 , 1fr);
      gap: 0 30px;
      justify-content: space-between;
      .cus-table-grid-items {

      }
  }
  .form-grid {
    display: grid;
    grid-template-columns: 250px minmax(0, 1fr);
    gap: 0 30px;
    margin-bottom: 30px;
    align-items: center;
    .form-grid-items {
        .button-alignment {
            display: flex;
            align-items: center;
            button {
                min-width: 110px;
                height: 40px;
                font-size: 14px;
                font-weight: 600;
                border-radius: 6px;
            }
            .submit-button-style {
                background-color: #ff5e5e;
                color: #fff;
                border: transparent;
                margin-right: 1rem;
            }
            .cancel-button-style {
                border: 1px solid #ff5e5e;
                color: #ff5e5e;
            }
        }
      label {
        font-size: 15px;
        display: block;
        color: #4a4a4a;
        span {
            color: red;
        }
      }
      textarea {
        width: 100%;
        background-color: transparent;
        padding: 0.375rem 0.75rem;
        font-size: 0.875rem;
        height: 100px;
        line-height: 1.5;
        border: 1px solid #ced4da;
        border-radius: 4px;
        &:focus {
            outline: none;
        }
      }
      input {
        width: 100%;
        background-color: transparent;
        padding: 0.375rem 0.75rem;
        font-size: 0.875rem;
        height: 45px;
        line-height: 1.5;
        border: 1px solid #ced4da;
        border-radius: 4px;
        &:focus {
            outline: none;
        }

      }
    }
  }
}
